.modal { z-index: 101; }
.modal-backdrop { z-index: 100; }
.modal-style-1 {
    .modal-header {
        border: none;
        padding-bottom: 0;
        justify-content: flex-end;
    }
    .modal-body {
        padding-top: 0;
    }
}

@media screen and (min-width: 576px) {
    .modal-style-1 {
        .modal-dialog { 
            max-width: 500px; 
        }
    }
}