.circle-location {
    background-color: $color-brand-2;
    border-radius: 20px;
    display: inline-block;
    margin: 0 3px;
    height: 20px;
    width: 20px;
}
p > .circle-location {
    transform: translateY(4px);
}

.grecaptcha-badge {
    z-index: 998;
}

.icon-fix { display: inline-block; transform: translateY(1px); }

.login-card {
    background-color: $color-grey-5;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
    padding: 20px;
    max-width: 1000px;
    width: 100%;

    .login_wrap {
        background-color: #fff;
        border-radius: 10px;
        padding: 20px;
    }
}

.bullet-list {
    li {
        padding-left: 20px;
        position: relative;

        &::before {
            content: "";
            background-color: $color-brand;
            border-radius: 4px;
            position: absolute;
            left: 0;
            top: .7em;
            height: 4px;
            width: 4px;
        }
    }
}

span.scroll-to-target {
    color: $color-brand;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
}

.rounded-icon {
    border-radius: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;

    i { font-size: 1.5em; line-height: 0; transform: translateY(1px); }
}
.rounded-icon-sm {
    border-radius: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    i { font-size: .8em; line-height: 0; }
}
.ri-brand { background-color: $color-brand; color: #fff; }
.ri-brand-2 { background-color: $color-brand-2; color: #fff; }

.separator {
    background: url('/storage/logo/logo-sprayboutic-illustration.svg') no-repeat center center;
    background-size: contain;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    height: 30px;
    width: 100%;

    &::before,
    &::after {
        content: "";
        background-color: rgba(0,0,0,.05);
        position: absolute;
        top: 50%;
        height: 2px;
        width: calc(50% - 60px);
    }
    &::before { left: 0; }
    &::after { right: 0; }
}
.brand-color-code-card {
    display: flex;
    align-items: center;
    justify-content: center;
}
.brand-color-code-wrapper {
    display: inline-block;
    margin-top: -50px;
    margin-bottom: -50px;
    position: relative;
    transform: rotate(90deg);
    svg { 
        position: absolute; 
        & > circle {
            opacity: 0;
            visibility: hidden;
            transform: scale(8);
            transform-box: fill-box;
            transform-origin: center center;
            transition: .65s ease-in-out;
        }
        & > circle.active {
            opacity: 1;
            visibility: visible;
            transform: scale(1.3);
        }
        & > circle.rendered {
            position: relative;

            &::before {
                content: "";
                background-color: $color-brand-2;
                position: absolute;
                top: 0;
                left: 0;
                height: 100px;
                width: 100px;
            }
        }
    }
}

.image-1 {
    display: inline-block;

    img {
        transition: .3s;
    }

    &:hover img {
        box-shadow: 0 5px 30px 0 rgba(#333, .1);
        transform: scale(1.02);
    }
}

@media screen and (max-width: 768px) {
    .brand-color-code-wrapper {
        margin-top: 0;
        margin-bottom: 0;
        transform: rotate(0);
    }
}