.list-icon-label {
    .lil-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;

        .icon {
            flex-shrink: 0;
            margin-right: 10px;
            img { display: block; }
        }

        .label {
            color: $color-brand;
            font-size: 17px;
            font-weight: bold;
        }
    }
}