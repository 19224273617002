.announcement {
    background-color: $color-grey-9;
    overflow: hidden;
    padding: 40px 15px;
    position: relative;
    text-align: center;

    &::before, &::after {
        content: "";
        background: url('/storage/icones/bullhorn-white.svg') center center no-repeat;
        background-size: cover;
        opacity: .85;
        position: absolute;
        top: 50%;
        height: 250px;
        width: 250px;
        transform: translateY(-50%);
        z-index: 0;
    }
    &::before {
        left: 2vw;
    }
    &::after {
        right: 2vw;
        transform: scaleX(-1) translateY(-50%);
    }

    & > * {
        position: relative;
        z-index: 1;
    }

    &-content {
        p { line-height: 1.4; margin-bottom: 10px; }
        u { 
            background-color: $color-brand-2;
            color: #fff;
            display: inline-block;
            font-size: 18px;
            font-weight: bold;
            padding: 3px 10px;
            text-decoration: none; 
        }
        strong {
            color: $color-brand;
        }
    }
}

@media screen and (max-width: 768px) {
    .announcement {
        &::before, &::after { display: none; }
    }
}