a,
button,
img,
input,
span,
h4 {
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}

@keyframes slideleft {
    10% {
        opacity: 0;
        transform: scale(0);
        right: 0
    }
    50% {
        opacity: 1;
        transform: scale(1)
    }
    90% {
        opacity: 0;
        transform: scale(0);
        right: 100%
    }
}

[data-loader='spinner'] {
    width: 35px;
    height: 35px;
    display: inline-block;
    -webkit-animation: spinner 1.2s infinite ease-in-out;
    -o-animation: spinner 1.2s infinite ease-in-out;
    animation: spinner 1.2s infinite ease-in-out;
    background: url(../imgs/favicon.svg);
    box-shadow: 0 0 10px #fff;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
        -ms-transform: perspective(120px) rotateX(0) rotateY(0);
        -o-transform: perspective(120px) rotateX(0) rotateY(0);
        transform: perspective(120px) rotateX(0) rotateY(0);
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
        -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
        -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
        transform: perspective(120px) rotateX(-180deg) rotateY(0);
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
        -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
        -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
        -ms-transform: perspective(120px) rotateX(0) rotateY(0);
        -o-transform: perspective(120px) rotateX(0) rotateY(0);
        transform: perspective(120px) rotateX(0) rotateY(0);
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
        -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
        -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
        transform: perspective(120px) rotateX(-180deg) rotateY(0);
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
        -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
        -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
        -ms-transform: perspective(120px) rotateX(0) rotateY(0);
        -o-transform: perspective(120px) rotateX(0) rotateY(0);
        transform: perspective(120px) rotateX(0) rotateY(0);
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
        -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
        -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
        transform: perspective(120px) rotateX(-180deg) rotateY(0);
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
        -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
        -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
        -ms-transform: perspective(120px) rotateX(0) rotateY(0);
        -o-transform: perspective(120px) rotateX(0) rotateY(0);
        transform: perspective(120px) rotateX(0) rotateY(0);
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
        -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
        -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
        transform: perspective(120px) rotateX(-180deg) rotateY(0);
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
        -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
        -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    }
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(239, 63, 72, 0.8);
    }
    100% {
        box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
    }
}

@keyframes shadow-pulse-big {
    0% {
        box-shadow: 0 0 0 0 rgba(239, 63, 72, 0.1);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes jump {
    0% {
        transform: translate3d(0, 20%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

.jump {
    transform-origin: 0;
    animation: jump .5s linear alternate infinite;
}

@-webkit-keyframes growTransp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        height: 20px;
        width: 20px;
    }
}

@keyframes growTransp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        height: 20px;
        width: 20px;
    }
}

@keyframes spinning {
    from { transform: rotate(0); }
    to { transform: rotate(360deg); }
}
@keyframes spinning-reversed {
    from { transform: rotate(0); }
    to { transform: rotate(-360deg); }
}
@keyframes spinning-reversed-center-y {
    from { transform: translateY(-50%) rotate(0); }
    to { transform: translateY(-50%) rotate(-360deg); }
}
