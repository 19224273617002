.table {
    margin-bottom: 0;
}

table.table-style-1 {
    thead > tr > th {
        border: none;
        border-bottom: 1px solid var(--color-grey-5);
        color: var(--color-brand);
        font-size: 14px;
    }
    tbody > tr:nth-child(even) > td {
        background-color: var(--color-grey-9);
    }
}

.table-label {
    tbody > tr > td {
        font-size: 15px;
        line-height: 1.2;
        padding: 6px;
        height: auto;
    }
    tbody > tr > td:first-child {
        color: var(--color-brand);
        font-weight: bold;
    }
    tbody > tr > td:last-child {
        color: var(--color-heading);
    }
}