//1.FONT

$font-heading: var(--font-heading), sans-serif;
$font-text: var(--font-text), sans-serif;

$font-xxs: 12px;
$font-xs: 13px;
$font-sm: 14px;
$font-md: 16px;
$font-lg: 17px;
$font-xl: 19px;
$font-xxl: 58px;

//2.COLOR
$color-brand: var(--color-brand);
$color-brand-dark: var(--color-brand-dark);
$color-brand-2: var(--color-brand-2);
$color-brand-3: var(--color-brand-3);
$color-primary: var(--color-primary);
$color-secondary: var(--color-secondary);
$color-warning: var(--color-warning);
$color-danger: var(--color-danger);
$color-success: var(--color-success);
$color-info: var(--color-info);
$color-text: var(--color-text);
$color-heading: var(--color-heading);
$color-grey-1: var(--color-grey-1);
$color-grey-2: var(--color-grey-2);
$color-grey-4: var(--color-grey-4);
$color-grey-5: var(--color-grey-5);
$color-grey-9: var(--color-grey-9);
$color-muted: var(--color-muted);
$color-body: var(--color-body);

$color-1: #fddde4;
$color-2: #cdebbc;
$color-3: #d1e8f2;
$color-4: #cdd4f8;
$color-5: #f6dbf6;
$color-6: #fff2e5;
$color-7: #d77f7a;
$color-8: #63a2c1;
$color-9: #F2FCE4;
$color-10: #FFFCEB;
$color-11: #ECFFEC;
$color-12: #FEEFEA;
$color-13: #FFF3EB;
$color-14: #FFF3FF;
$color-15: #F2FCE4;

$color-hot: #f74b81;
$color-new: #55bb90;
$color-sale: #67bcee;
$color-best: #f59758;

//3.Boxshadow
$box-shadown-1: 20px 20px 40px rgba(0, 0, 0, .07);

$box-shadown-2: 5px 5px 15px rgba(0, 0, 0, 0.05);
$box-shadown-3: 20px 20px 54px rgba(0, 0, 0, 0.03);
$box-shadown-3-hover: 20px 20px 54px rgba(0, 0, 0, 0.05);


//4. Border
$border-color: #ececec;
$border-color-2: #BCE3C9;

//5. Background
$background-1: #F2F3F4;
$background-2: #F4F6FA;
$background-3: #DEF9EC;

