.vendor-wrap {
    position: relative;
    background-color: #fff;
    border: 1px solid $border-color;
    border-radius: 15px;
    overflow: hidden;
    @include transistion-duration-2s;

    &:hover {
        box-shadow: $box-shadown-2;
        border: 1px solid rgba(var(--color-brand-rgb), 0.4);
        @include transistion-duration-2s;
    }

    .vendor-img-action-wrap {
        padding: 25px 25px 0 25px;

        img {
            max-width: 144px;
        }
    }

    .vendor-content-wrap {
        padding: 20px 20px 30px 20px;

        h4 {
            a {
                color: $color-heading;

                &:hover {
                    color: $color-brand;
                }
            }
        }

        .total-product {
            position: relative;
            display: inline-block;
            padding: 6px 20px 6px 20px;
            border-radius: 4px;
            background-color: #DEF9EC;
            font-size: 14px;
            font-weight: 700;
            color: $color-brand;
            min-width: 123px;
        }
    }

    .product-badges.product-badges-position {
        position: absolute;
        right: 0;
        top: 0px;
        z-index: 9;

        span {
            display: inline-block;
            font-size: 12px;
            line-height: 1;
            border-radius: 0px 10px 0 20px;
            color: #fff;
            padding: 9px 20px 10px 20px;
            background-color: $color-brand;

            &.hot {
                background-color: $color-hot;
            }

            &.new {
                background-color: $color-brand;
            }

            &.sale {
                background-color: $color-sale;
            }

            &.best {
                background-color: $color-best;
            }
        }
    }

    &.style-2 {
        display: flex;

        .vendor-img-action-wrap {
            position: relative;
            padding: 20px;

            .total-product {
                position: relative;
                display: inline-block;
                padding: 6px 20px 6px 20px;
                border-radius: 4px;
                background-color: #DEF9EC;
                font-size: 14px;
                font-weight: 700;
                color: $color-brand;
                min-width: 123px;
            }
        }

        .vendor-info {
            .btn {
                min-width: 105px;
                padding: 8px 10px 8px 13px;
            }
        }
    }
}

.archive-header-2 {
    .search-form {
        input {
            border-radius: 30px;
            padding-left: 30px;
            box-shadow: $box-shadown-1;
        }
    }
}

.archive-header-3 {
    position: relative;
    border-radius: 20px;
    padding: 50px;

    .archive-header-3-inner {
        display: flex;
    }
}
