.with-loader {
    & ~ .custom-loader {
        color: $color-brand-3;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    
        &::before {
            content: "";
            background: url('/storage/logo/logo-sprayboutic-illustration.svg') center center no-repeat;
            background-size: cover;
            margin-right: 15px;
            height: 40px;
            width: 40px;
            animation: spinning-reversed 1s infinite linear;
        }
    }

    &.is-loaded ~ .custom-loader {
        display: none;
    }
}
