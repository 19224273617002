.step-card {
    position: relative;
    margin: 40px auto 0 auto;
    max-width: 800px;

    .step-nbr {
        background-color: $color-brand-2;
        border-radius: 50px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        padding: 6px 20px;
        position: absolute;
        top: 0;
        left: 15px;
        transform: translateY(-50%);
    }

    .step-content {
        background-color: $color-grey-9;
        padding: 30px 20px 20px 20px;
    }
}