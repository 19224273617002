.bg-grey-5 { background-color: $color-grey-5 !important; }
.bg-grey-9 { background-color: $color-grey-9 !important; }

.mr-0 { margin-right: 0 !important; }

.pos-rel { position: relative !important; }

.text-brand { color: $color-brand !important; }
.text-brand-2 { color: $color-brand-2 !important; }
.text-secondary { color: $color-secondary !important; }
.text-justify { text-align: justify !important; }
.text-sm { font-size: 13px !important; line-height: 1.5 !important; }
.text-right { text-align: right !important; }
.text-underline { text-decoration: underline; }

.z-index-1 { z-index: 1 !important; }