.tabulator-style-1 {
    background-color: transparent;
    border: none;

    // Tabulator header
    .tabulator-header {
        background-color: transparent;
        border-bottom: 1px solid $color-brand;
        .tabulator-col {
            background-color: transparent;
            border-right: none;
            color: $color-brand;
        }
    }
    
    // Tabulator body
    .tabulator-row {
        .tabulator-cell {
            border-right: 1px solid $color-brand;
            font-size: 15px;
            padding: 8px;

            &[tabulator-field="colorCode"] {
                color: $color-body;
                font-weight: bold;
            }

            .tabulator-responsive-collapse-toggle {
                background: $color-brand;
                font-size: 23px;
                height: 30px;
                width: 30px;
            }
        }
        .tabulator-responsive-collapse {
            table {
                td { border-color: $color-brand; }
            }
        }
    }
    .tabulator-row.tabulator-row-even {
        background-color: $color-grey-9;
    }
    .tabulator-row.tabulator-selectable:hover {
        background-color: $color-grey-5;
        cursor: default;
    }

    // Tabulator footer
    .tabulator-footer {
        background-color: transparent;

        .tabulator-paginator {
            padding-top: 10px;
            text-align: center;
        }

        .tabulator-page {
            border: 1px solid $color-brand;
            color: $color-brand;
            font-size: 16px;
            padding: 8px 20px;

            &:hover {
                background-color: $color-brand;
                color: #fff;
            }

            &:disabled { cursor: default; }
            &:disabled, &:disabled:hover {
                background-color: transparent;
                border: 1px solid $color-grey-5;
                color: $color-grey-5;
            }
        }

        .tabulator-pages {
            .tabulator-page {
                border: 1px solid $color-brand;
                border-radius: 40px;
                color: $color-brand;
                display: inline-flex;
                font-size: 16px;
                align-items: center;
                justify-content: center;
                padding: 0;
                height: 40px;
                width: 40px;

                &.active {
                    background-color: $color-brand;
                    color: #fff;
                    font-weight: bold;
                }
                &:hover:not(.active), &:focus:not(.active) {
                    background-color: $color-grey-5;
                    border-color: $color-grey-5;
                    color: $color-brand;
                }
            }
        }
    }

}