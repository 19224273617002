.select2-button + .select2.select2-container--default {
    max-width: 600px;
    width: 100% !important;
    .select2-selection {
        border: none;
        height: auto;

        &__rendered {
            background-color: $color-brand-3;
            color: #fff;
            font-size: 22px;
            letter-spacing: .02em;
            line-height: 1.3;
            padding: 30px 40px;
            // padding-top: 20px;
            // padding-bottom: 20px;
            // padding-left: 15px;
            // padding-right: 40px;
            white-space: normal;
        }

        &__placeholder {
            color: #fff;
            display: inline-block;
            text-transform: uppercase;
        }

        &__arrow {
            display: none;
            // top: 50%;
            // right: 20px;
            // transform: translateY(-45%);
            // height: auto;
            // width: auto;

            // &::before {
            //     content: "\f147";
            //     color: $color-brand;
            //     font-family: uicons-regular-straight !important;
            //     font-size: 35px;
            //     font-style: normal;
            //     font-weight: normal !important;
            //     font-variant: normal;
            //     text-transform: none;
            //     line-height: 1;
            //     -webkit-font-smoothing: antialiased;
            // }

            // b { display: none; }
        }
    }
}
.select2-style1-dropdown.select2-dropdown {
    .select2-results > .select2-results__options {
        max-height: 300px;
    }
    .select2-results__option {
        display: flex;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0;

        img {
            flex-shrink: 0;
            margin-right: 10px;
            width: 55px;
        }
        span {
            color: $color-brand-3;
            font-size: 18px;
            font-weight: bold;
        }
    }
    .select2-results__option--highlighted.select2-results__option--selectable {
        background-color: $color-brand;
        span { color: #fff; }
    }
}

.select2-brands + .select2.select2-container--default {
    max-width: 100%;
    width: 100% !important;
    .select2-selection {
        background-color: transparent;
        border: none;
        height: 48px;

        &__rendered {
            background-color: #fff;
            border: 1px solid $border-color;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 16px;
            font-weight: normal;
            padding: 2px 20px 2px 0;
            height: 48px;

            img {
                flex-shrink: 0;
                width: 65px;
            }
        }
    }
}