/*LAYOUT -> NAVIGATION STYLE*/
.main-menu {
    &.main-menu-mrg-1 {
        margin: 0 0 0 27px;
    }

    & > nav {
        & > ul {
            & > li {
                &.hot-deals {
                    padding-right: 30px !important;

                    img {
                        position: relative;
                        margin-bottom: -4px;
                        margin-right: 8px;
                    }
                }

                &.position-static {
                    position: static;
                }

                & > a {
                    display: inline-block;
                    font-size: 15px;
                    font-weight: 700;
                    color: #fff;
                    font-family: $font-heading;
                    text-transform: uppercase;

                    i {
                        font-size: 8px;
                        position: relative;
                        margin-left: 4px;
                    }

                    /*&.active {
                        color: $color-brand;
                    }*/

                }

                ul {
                    &.sub-menu {
                        position: absolute;
                        left: 0;
                        top: 100%;
                        background-color: #ffffff;
                        min-width: 250px;
                        padding: 25px 15px;
                        -webkit-transition: all .25s ease 0s;
                        -o-transition: all .25s ease 0s;
                        transition: all .25s ease 0s;
                        opacity: 0;
                        visibility: hidden;
                        margin-top: 20px;
                        border-radius: 10px;
                        z-index: 999;
                        border: 1px solid $border-color;
                        box-shadow: $box-shadown-2;

                        li {
                            line-height: 1;
                            display: block;
                            margin-bottom: 21px;
                            position: relative;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            ul {
                                &.level-menu {
                                    position: absolute;
                                    left: 100%;
                                    top: -110px;
                                    background-color: #ffffff;
                                    width: 240px;
                                    padding: 33px 0 35px;
                                    -webkit-transition: all .25s ease 0s;
                                    -o-transition: all .25s ease 0s;
                                    transition: all .25s ease 0s;
                                    opacity: 0;
                                    visibility: hidden;
                                    border-radius: 10px;
                                    margin-top: 20px;
                                    border: 1px solid $border-color;
                                    box-shadow: $box-shadown-2;

                                    &.level-menu-modify {
                                        top: -25px;
                                    }

                                }

                            }

                            a {
                                i {
                                    font-size: 9px;
                                    float: right;
                                    position: relative;
                                    top: 4px;
                                }

                            }

                            &:hover {
                                & > a {
                                    color: #000;
                                }

                                > ul {
                                    &.level-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        margin-top: 0;
                                    }

                                }

                            }

                        }

                    }

                    &.mega-menu {
                        position: absolute;
                        left: 0;
                        top: 100%;
                        background-color: #ffffff;
                        width: 100%;
                        padding: 35px 25px 35px 35px;
                        -webkit-transition: all .25s ease 0s;
                        -o-transition: all .25s ease 0s;
                        transition: all .25s ease 0s;
                        opacity: 0;
                        visibility: hidden;
                        margin-top: 20px;
                        border-radius: 0 0 10px 10px;
                        z-index: 999;
                        border: 1px solid $border-color;
                        box-shadow: $box-shadown-2;

                        li {
                            line-height: 1;
                            display: block;
                            position: relative;
                            float: left;
                            padding-right: 10px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &.sub-mega-menu-width-22 {
                                width: 22%;
                            }

                            &.sub-mega-menu-width-34 {
                                width: 34%;
                            }

                            a {
                                &.menu-title {
                                    font-size: $font-xl;
                                    font-weight: 700;
                                    display: block;
                                }

                            }

                            ul {
                                margin-top: 28px;

                                li {
                                    line-height: 1;
                                    display: block;
                                    margin-bottom: 14px;
                                    float: none;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }

                                    a {
                                        font-size: 15px;
                                        color: $color-body;
                                        display: block;
                                        line-height: 1.4;

                                        &:hover {
                                            color: $color-brand;
                                        }

                                    }

                                }

                            }

                            .menu-banner-wrap {
                                overflow: hidden;
                                position: relative;

                                a {
                                    display: block;

                                    img {
                                        width: 100%;
                                    }

                                }

                                .menu-banner-content {
                                    position: absolute;
                                    top: 32px;
                                    left: 30px;

                                    h3 {
                                        font-size: 24px;
                                        font-weight: 700;
                                        line-height: 1.5;
                                        margin: 5px 0 11px;
                                    }

                                    .menu-banner-price {
                                        span {
                                            &.old-price {
                                                font-size: $font-xl;
                                                font-weight: 400;
                                                color: #696969;
                                                text-decoration: line-through;
                                                margin-left: 5px;
                                            }

                                        }

                                    }

                                    .menu-banner-btn {
                                        a {
                                            display: inline-block;
                                            font-size: $font-md;
                                            font-weight: 700;
                                            color: #ffffff;
                                            border-radius: 26px;
                                            padding: 12px 22px 14px;
                                            background-color: $color-brand;
                                        }

                                    }

                                }

                                .menu-banner-discount {
                                    h3 {
                                        span {
                                            display: block;
                                            line-height: 1;
                                            font-weight: 700;
                                            font-size: 20px;
                                            margin: 0 0 3px;
                                        }

                                    }

                                }

                                &:hover {

                                    .menu-banner-btn {
                                        a {
                                            background-color: $color-danger;
                                        }

                                    }

                                }

                            }

                        }

                    }

                }

                &:hover {
                    & > a {
                        color: $color-brand-2;
                    }

                    ul {
                        &.sub-menu {
                            opacity: 1;
                            visibility: visible;
                            margin-top: 12px;
                        }

                        &.mega-menu {
                            opacity: 1;
                            visibility: visible;
                            margin-top: 0;
                        }

                    }

                }

            }

        }

    }

    &.hover-boder {
        & > nav {
            & > ul {
                & > li {
                    & > a {
                        position: relative;

                        &::after {
                            content: none;
                            position: absolute;
                            left: auto;
                            right: 0;
                            bottom: 34px;
                            height: 1px;
                            width: 0;
                            -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
                            -o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
                            transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
                            background: $color-brand;
                        }

                    }

                    &:hover {
                        & > a {
                            &::after {
                                width: 100%;
                                left: 0;
                                right: auto;
                            }

                        }

                    }

                }

            }

        }

        &.hover-boder-white {
            & > nav {
                & > ul {
                    & > li {
                        & > a {
                            &::after {
                                bottom: 18px;
                                background: #ffffff;
                            }

                        }

                    }

                }

            }

        }

        &.hover-boder-modify {
            & > nav {
                & > ul {
                    & > li {
                        & > a {
                            &::after {
                                bottom: 28px;
                            }

                        }

                    }

                }

            }

        }

    }

    &.main-menu-light-white {
        & > nav {
            & > ul {
                & > li {
                    & > a {
                        color: white;
                    }

                }

            }

        }

    }

    &.main-menu-padding-1 {
        & > nav {
            & > ul {
                & > li {
                    padding: 0 17px;

                    &:first-child {
                        padding-left: 0 !important;
                    }
                }

            }

        }

    }

    &.main-menu-lh-2 {
        & > nav {
            & > ul {
                & > li {
                    line-height: 70px;
                }

            }

        }

    }

    &.main-menu-lh-3 {
        & > nav {
            & > ul {
                & > li {
                    line-height: 80px;
                }

            }

        }

    }

}

.main-menu {
    &.main-menu-grow {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    & > nav {
        & > ul {
            & > li {
                display: inline-block;
                position: relative;

                ul {
                    &.sub-menu {
                        li {
                            a {
                                font-size: 14px;
                                color: $color-text;
                                display: block;
                                padding: 0 15px;

                                &:hover {
                                    color: $color-brand;
                                }
                            }

                        }

                    }

                    &.mega-menu {
                        li {
                            .menu-banner-wrap {
                                .menu-banner-content {
                                    h4 {
                                        font-size: $font-md;
                                        text-transform: uppercase;
                                        font-weight: 500;
                                        margin: 0;
                                    }

                                    .menu-banner-price {
                                        margin: 0 0 28px;

                                        span {
                                            &.new-price {
                                                font-size: 24px;
                                                font-weight: 700;
                                                color: $color-danger;
                                            }
                                        }
                                    }
                                }

                                .menu-banner-discount {
                                    width: 80px;
                                    height: 80px;
                                    line-height: 80px;
                                    border-radius: 100%;
                                    display: -webkit-box;
                                    display: -webkit-flex;
                                    display: -ms-flexbox;
                                    display: flex;
                                    -webkit-box-pack: center;
                                    -webkit-justify-content: center;
                                    -ms-flex-pack: center;
                                    justify-content: center;
                                    -webkit-box-align: center;
                                    -webkit-align-items: center;
                                    -ms-flex-align: center;
                                    align-items: center;
                                    background-color: #ffd55a;
                                    position: absolute;
                                    top: 13%;
                                    right: 7%;

                                    h3 {
                                        color: #333;
                                        margin: 0;
                                        text-align: center;
                                        font-size: $font-xl;
                                        font-weight: 600;
                                        line-height: 1;
                                    }

                                }

                            }

                        }

                    }

                }

            }

        }

    }

    &.main-menu-lh-1 {
        & > nav {
            & > ul {
                & > li {
                    line-height: 70px;
                }

            }

        }

    }

}

.main-categories-wrap {
    position: relative;
    margin-right: 35px;

    & > a {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex !important;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 18px;
        font-weight: 700;

        span {
            margin-right: 7px;
            color: $color-body;
        }

        & > i {
            margin-left: 5px;
            margin-top: 7px;
            color: #999;

            &.up {
                position: absolute;
                top: 40%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                right: 0;
                opacity: 0;
                visibility: hidden;
            }
        }

        &.open {
            & > i {
                transform: rotate(180deg);
            }

        }

    }

    .categories-dropdown-inner {
        min-width: 575px;
    }
}


.categories-dropdown-active-large {
    list-style-type: none;
    position: absolute;
    top: 177%;
    left: 0;
    z-index: 99;
    margin: 0;
    padding: 30px;
    background: #fff;
    border: 1px solid rgba(var(--color-brand-rgb), 0.4);
    border-radius: 10px;
    font-size: $font-md;
    min-width: 270px;
    -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
    visibility: hidden;
    margin-top: 26px;

    &.open {
        opacity: 1;
        visibility: visible;
    }

}

.categories-dropdown-active-small {
    z-index: 9;
    margin: 0;
    padding: 14px 0 23px;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: $box-shadown-1;
    box-shadow: $box-shadown-1;
    font-size: $font-md;
    font-weight: 400;
    display: none;
    width: 100%;
}

.categories-dropdown-wrap {
    ul {
        display: flex;
        flex-flow: row wrap;
        overflow-y: scroll;
        max-height: 275px;

        li {
            display: flex;
            align-items: center;
            flex: 0 0 auto;
            line-height: 1;
            border-radius: 5px;
            border: 1px solid $background-1;
            padding: 5px 15px 5px 5px;
            margin: 0 15px 8px 0;
            height: 40px;
            width: calc(33.333% - 15px);
            @include transistion-duration-3s;

            &:hover {
                border: 1px solid rgba(var(--color-brand-rgb), 0.4);
                box-shadow: $box-shadown-2;
                @include transistion-duration-3s;

                a {
                    color: $color-brand;
                }
            }

            a {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                padding: 0;
                line-height: 1.5;
                color: $color-heading;
                font-size: $font-sm;

                &:hover {
                    color: $color-brand;
                    background-color: transparent;
                }

                img {
                    max-width: 60px;
                    margin-right: 0;
                }
            }

            &:hover .dropdown-menu {
                display: block;
                opacity: 1;
                visibility: visible;
                margin-top: 0px;
                pointer-events: auto;
            }

            &.has-children {
                position: relative;

                > a::after {
                    content: "\f111";
                    font-family: 'uicons-regular-straight' !important;
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    margin-top: -10px;
                }

                .dropdown-menu {
                    left: 100%;
                    top: 0;
                    margin: 0;
                    margin-top: 0px;
                    border: 1px solid $border-color;
                    min-width: 800px;
                    width: 100%;
                    right: 0;
                    border-radius: 0;
                    padding: 20px;

                    .submenu-title {
                        font-size: $font-xl;
                        font-weight: 700;
                        display: block;
                        color: $color-brand;
                        padding: 5px 34px;
                    }

                    .header-banner2 {
                        display: block;
                        position: relative;
                        margin-bottom: 15px;

                        .banne_info {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            -moz-transform: translateY(-50%);
                            -webkit-transform: translateY(-50%);
                            transform: translateY(-50%);
                            padding: 15px;

                            a {
                                text-transform: capitalize;
                                position: relative;
                                padding: 0;
                                color: #272a2c !important;

                                &::before {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    height: 1px;
                                    width: 50%;
                                    background-color: #272a2c;
                                    transition: all 0.5s ease-in-out;
                                }
                            }

                        }
                    }
                }
            }

        }

        &.end {
            li {
                margin-right: 0;
            }
        }
    }

    .more_categories {
        margin-top: 15px;
        color: $color-brand;
        position: relative;
        font-size: 13px;
        font-family: $font-heading;
        display: flex;
        justify-content: center;
        align-items: center;

        span.icon {
            display: inline-block;
            position: relative;
            width: 24px;
            height: 24px;
            border: 2px solid;
            border-radius: 30px;
            margin-right: 5px;

            &::before {
                content: "";
                width: 12px;
                height: 2px;
                background-color: #3BB77E;
                position: absolute;
                right: 4px;
                top: 9px;
            }

            &::after {
                content: "";
                width: 2px;
                height: 12px;
                background-color: #3BB77E;
                position: absolute;
                right: 9px;
                top: 4px;
            }

        }

        &.show {
            span.icon::after {
                display: none;
                content: none;
            }
        }

    }

    &.style-2 {
        border: 1px solid rgba(var(--color-brand-rgb), 0.4);
        border-radius: 10px;
        padding: 30px;
        ul {
            li {
                border-radius: 0;
                border: 0;
                height: 24px;
                padding: 0;
                line-height: 24px;
                margin-bottom: 16px;
                &:hover {
                    box-shadow: none;
                }
                a {
                    font-weight: 500;
                }
                img {
                    max-height: 20px;
                }
            }
        }
        .more_categories {
            justify-content: left;
            .icon {
                transform: scale(0.7);
            }
        }
    }
}


/*Pagination*/
.pagination-area {
    .page-item {
        margin: 0 5px;

        &:first-child {
            margin-left: 0;

            .page-link {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }

        &:last-child {
            .page-link {
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
            }
        }

        &.active, &:hover {
            .page-link {
                color: #fff;
                background: $color-brand;
            }
        }

        &:last-child {
            .page-link {
                border-top-right-radius: 40px;
                border-bottom-right-radius: 40px;
                line-height: 43px;
            }
        }

        &:first-child .page-link {
            border-top-left-radius: 40px;
            border-bottom-left-radius: 40px;
            line-height: 43px;
        }
    }

    .page-link {
        border: 0;
        padding: 0 10px;
        box-shadow: none;
        outline: 0;
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 40px;
        color: $color-text;
        line-height: 40px;
        text-align: center;
        font-weight: 700;
        font-family: $font-heading;
        font-size: $font-md;
        background-color: $background-1;

        &.dot {
            background-color: transparent;
            color: $color-text;
            letter-spacing: 2px;
        }
    }

}
