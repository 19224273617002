.container-xs {
    margin: 0 auto; 
    padding-left: .75rem;
    padding-right: .75rem;
}

.main {
    position: relative;
    z-index: 2;
}

.newsletter {
    position: relative;
    z-index: 3;
}

.section-color-powder {
    position: relative;
    text-align: center;
    z-index: 0;

    &::before {
        content: "";
        background:url('/storage/backgrounds/multicolor-powder-explosion-w1000.jpg') center center no-repeat;
        background-size: cover;
        opacity: .35;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 500px;
        height: 354px;
        z-index: 0;
    }
    
    & > * {
        position: relative;
        z-index: 1;
    }
}

@media screen and (min-width: 576px) {
    .container-xs { 
        max-width: 540px; 
    }
}
@media screen and (min-width: 768px) {
    .container-xs { 
        max-width: 720px; 
    }
}
@media screen and (min-width: 1000px) {
    .container-xs { 
        max-width: 992px; 
    }
}