/*TYPOGRAPHY*/
body {
    color: $color-text;
    font-family: $font-text;
    font-size: 14px;
    line-height: 24px;
    font-style: normal;
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.font-heading,
.display-1,
.display-2,
.heading-sm-1 {
    font-family: $font-heading;
    color: $color-heading;
    font-weight: 700;
    line-height: 1.2;
}

h1, .h1 {
    font-size: 48px;
}

h2, .h2 {
    font-size: 40px;
}

h3, .h3 {
    font-size: 32px;
}

h4, .h4 {
    font-size: 24px;
}

h5, .h5 {
    font-size: 20px;
}

h6, .h6 {
    font-size: 16px;
}

.display-1 {
    font-size: 96px;
    line-height: 1;
}

.display-2 {
    font-size: 72px;
    line-height: 1;
}

.heading-sm-1 {
    font-size: 14px;
}

p, .para {
    font-size: .9rem;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px;
    color: $color-text;
}

.text-heading {
    color: $color-heading;
}

p:last-child {
    margin-bottom: 0;
}

.font-weight-bold {
    font-weight: 700;
}

a,
button {
    text-decoration: none;
    cursor: pointer;
}

b {
    font-weight: 500;
}

strong {
    font-weight: bold;
}

.fw-600 {
    font-weight: 600;
}

.fw-900 {
    font-weight: 900;
}

.fw-300 {
    font-weight: 300;
}

.section-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    // align-items: end;
    margin-bottom: 44px;
    position: relative;
    justify-content: space-between;

    .title {
        display: flex;
        flex-flow: row;
        align-items: flex-end;
    }

    h3 {
        font-weight: 700;
        font-size: 32px;
        margin-right: 30px;
    }

    a.show-all {
        font-size: $font-md;
        color: $color-text;

        &:hover {
            color: $color-brand;
        }

        i {
            font-size: 12px;
            margin-left: 5px;
        }
    }

    &.style-1 {
        position: relative;
        border-bottom: 1px solid $border-color;
        padding-bottom: 20px;
        font-size: 24px;

        &::after {
            content: "";
            width: 80px;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: rgba(var(--color-brand-rgb), 0.4);
        }
    }

    span {
        color: $color-brand;
    }
}

.widget-title {
    color: $color-brand;
    font-size: 20px;
    font-weight: 600;
}

.title.style-3 {
    background-image: url(../imgs/theme/wave.png);
    background-position: center bottom;
    background-repeat: no-repeat;
    padding-bottom: 25px;
}

.text-body {
    color: $color-text !important;
}

.font-xxs {
    font-size: $font-xxs;
}

.font-xs {
    font-size: $font-xs;
}

.font-sm {
    font-size: $font-sm;
}

.font-md {
    font-size: $font-md;
}

.font-lg {
    font-size: $font-lg;
}

.font-xl {
    font-size: $font-xl;
}

.font-xxl {
    font-size: $font-xxl;
}

.text-hot {
    color: $color-hot
}

.text-new {
    color: $color-new
}

.text-sale {
    color: $color-sale
}

.text-best {
    color: $color-best
}

.text-style-1 {
    position: relative;

    &::after {
        content: "";
        background-color: #ffdabf;
        height: 20%;
        width: 110%;
        display: block;
        position: absolute;
        bottom: 20%;
        left: -5%;
        z-index: -1;
        opacity: 0.8;
        @include transform-duration-5s;
    }

    &:hover::after {
        height: 30%;
        @include transform-duration-5s;
    }
}

.fw-700 {
    font-weight: 700;
}

.link-classic-1 {
    color: $color-brand-3;
    display: inline-block;
    font-weight: bold;
    text-decoration: underline;

    &:hover, &:focus { color: $color-brand; }
}

// Dynamic content
.dyna-content {

    h2 {
        color: $color-brand;
        font-size: 30px;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    p {
        .btn {
            background-color: transparent;
            color: $color-brand;
            padding: 0;
            text-decoration: underline;
            text-transform: none;
        }
        .btn:hover {
            color: $color-brand-2;
        }
    }

    // Dynamic content for page products
    &-products {
        h1 {
            color: $color-brand;
            font-size: 30px;
            margin-bottom: 10px;
        }
        h2 {
            font-size: 20px;
            margin-bottom: 10px;
        }
        ul {
            padding-left: 20px;
            list-style-type: circle;
            margin-bottom: 10px;
        }
        p, li {
            color: $color-grey-4;
            font-size: 13px;
            line-height: 1.5;
        }
    }
}
