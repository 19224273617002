@mixin transform-duration-5s {
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    -o-transition: transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
}

@mixin transistion-duration-3s {
    transition: .3s;
    -moz-transition: .3s;
    -webkit-transition: .3s;
    -o-transition: .3s;
}

@mixin transistion-duration-2s {
    transition: .2s;
    -moz-transition: .2s;
    -webkit-transition: .2s;
    -o-transition: .2s;
}
